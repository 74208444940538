html,
body {
  height: 100%;
  background-color: #282c34;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  background-color: "#282c34";
}

.app-container {
  /* height: 100%; */
  padding-top: 35px;
  padding-bottom: 20px;
}

h1 {
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  text-align: center;
}

.book {
  text-align: center;
  font-size: 1.2rem;
  padding: 2px;
}

.start {
  margin-left: 15px;
  font-size: 0.9rem;
  font-style: italic;
}

/* .react-toggle--checked .react-toggle-track {
  background-color: #ab199f;
} */
